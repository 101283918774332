@media screen and (max-width: 767px) {
    .arrow-steps {
    	.step {
    		min-width: 40px;
    		.fa {
    			padding-left: 15px;
    		}
    	}
    }
}

@media screen and (min-width: 768px) {
	.double-line {
		min-height: 44px;
	}
}