/** Common **/
a, a:hover, a:active, a:visited, a:focus {
    text-decoration:none;
}

.btn-theme1 {
    color: #fff;
    background-color: #9e262a;
    border-color: #9e262a;
    &:hover {
      color: #fff;
      background-color: #8a2225;
      border-color: #9e262a;
    }
}

.btn-del {
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    text-shadow: 0 0 1px #bf5329;
    transition: 0.5s;
  }
}

.error-messages {
  color: #ff3c3c;
}
.error-message {
  color: #ff3c3c;
  margin-left: 30px;
}

.f-white {
  color: #FFF;
}

.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}

.form-signin .checkbox {
  font-weight: normal;
}

.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="text"],.form-signin input[type="email"],.form-signin input[type="password"] {
  margin-bottom: 10px;
  // border-bottom-left-radius: 0;
  // border-bottom-right-radius: 0;
  // border-top-left-radius: 0;
  // border-top-right-radius: 0;
}

// .form-signin input[type="password"] {
//   margin-bottom: 10px;
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
// }

.form-content input {
  margin-bottom: 5px;
}
.form-submit {
  padding-top: 25px;
  .btn {
    // margin: 5px;
  }
}

.form-mg-btm-0 .form-group {
  margin-bottom: 0px;
}

.account-wall {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 40px 0px 20px 0px;
  background-color: #f7f7f7;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.login-title {
  color: #555;
  font-size: 20px;
  font-weight: 400;
  display: block;
  margin-top: 0px;
  margin-bottom: 25px;
}

.profile-img {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.need-help {
  margin-top: 10px;
}

.new-account {
  display: block;
  margin-top: 10px;
}

.form-header {
    border-left: 4px solid #5b9bd1;
    // font-weight: 800;
    background-color: #ffebbe;
    padding: 7px 15px;
    min-width: 180px;
    position:relative;
    height: 34px;
    width: 95%;
    margin-bottom: 20px;

    &:after{
    content:"";
    position:absolute;
    height:0;
    width:0;
    left:100%;
    top:0;
    border:17px solid transparent;
    border-left: 17px solid #ffebbe;
}
}

.prev-next-block {
  margin-bottom: 5px;
  margin-top: 5px;
}

.small-padding {
  padding: 5px;
}

.q-title {
  font-size: 15px;
  font-weight: bold;
}
/** Header **/

/*
Ref:
Thanks to:
http://span-tag.com/bootstrap-navbar/
*/

body {
  // min-height: 2000px;
  // margin-top: 75px;
}

/* added to  .nav */

.normal {
  height: 75px;
  /* this causes drop down to lose color ??? */

-webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.normal .navbar-right {
  padding-top: 20px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.navbar-brand {
  padding: 0px !important;
}

.navbar-brand img {
  position: absolute;
  z-index: 10;
  padding: 5px;
  max-height: 75px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  background-color: #303030;
}

.navbar-inverse {
  background-color: #303030;
  border-color: #303030;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
  color: #f90500;
  background-color: transparent;
}

.table {
  // table-layout: fixed;
}

.shrink {
  height: 50px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.shrink .navbar-right {
  padding-top: 0px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

.shrink .navbar-brand img {
  max-height: 50px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  transition: 0.5s;
}

/** General **/

.content {
  padding-top: 75px;
  min-height: calc(100vh - 103px);
  // background: linear-gradient(rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.80)), url('/img/assets/bg.jpg');
  // background-image: url('/img/assets/bg.jpg');
  background-size: cover;
  // padding-top: 75px;
}

.content-app {
  padding-top: 75px;
  min-height: calc(100vh - 103px);
  background: linear-gradient(rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.80)), url('/img/assets/bg.jpg');
  background-size: cover;
}

.content-admin {
  padding-top: 75px;
  min-height: calc(100vh - 103px);
  // background: linear-gradient(rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.80)), url('/img/assets/bg.jpg');
  background-size: cover;
}

.home-header-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.4)), url('/img/home/banner.jpg');
  background-size: cover;
}

p,h2,h1 {
    color: #303030;
}

footer {
  text-align: center;
  p {
    a {
    color: #ddd !important;
    margin-right: 20px;
    }
  }  
}

.mr-10 {
  margin-right: 10px;
}
.mr-5 {
  margin-right: 5px;
}

#profileForm {
  .help-block {
    margin-top: 3px;
    margin-bottom: 0px;
    font-style: italic;
  }
  .form-group {
    margin-bottom: 5px;
  }
}

.hide{
  display: none
}
/** Login **/

.login {
  margin-top: 70px;
}

/** Side menu **/
/* Profile container */
.profile {
  margin: 20px 0;
}

/* Profile sidebar */
.profile-sidebar {
  padding: 20px 15px 20px 15px;
  background: #fff;
  border: 1px solid #e0e0e0;
  margin-bottom: 15px;
}

.profile-userpic img {
  float: none;
  margin: 0 auto;
  border: 1px solid #e8e8e8;
  // width: 50%;
  // height: 50%;
  // -webkit-border-radius: 50% !important;
  // -moz-border-radius: 50% !important;
  // border-radius: 50% !important;
}

.profile-usertitle {
  text-align: center;
  margin-top: 20px;
}

.profile-usertitle-name {
  color: #5a7391;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 7px;
}

.profile-usertitle-job {
  text-transform: uppercase;
  color: #5b9bd1;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}

.profile-userbuttons {
  text-align: center;
  margin-top: 10px;
}

.profile-userbuttons .btn {
  // text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  padding: 6px 15px;
  margin-right: 5px;
}

.profile-userbuttons .btn:last-child {
  margin-right: 0px;
}
    
.profile-usermenu {
  margin-top: 30px;
}

.profile-usermenu ul li {
  border-bottom: 1px solid #f0f4f7;
}

.profile-usermenu ul li:last-child {
  border-bottom: none;
}

.profile-usermenu ul li a {
  color: #93a3b5;
  font-size: 14px;
  font-weight: 400;
}

.profile-usermenu ul li a i {
  margin-right: 8px;
  font-size: 14px;
}

.profile-usermenu ul li a:hover {
  background-color: #fafcfd;
  color: #5b9bd1;
}

.profile-usermenu ul li.active {
  border-bottom: none;
}

.profile-usermenu ul li.active a {
  color: #5b9bd1;
  background-color: #f6f9fb;
  border-left: 2px solid #5b9bd1;
  margin-left: -2px;
}

/* Profile Content */
.profile-content {
  padding: 20px;
  background: #fff;
  min-height: 460px;
}

/* Breadcrups CSS */

.arrow-steps {
    margin-bottom: 15px;
}
.arrow-steps .step {
    font-size: 14px;
    text-align: center;
    color: #666;
    cursor: default;
    margin: 3px;
    padding: 7px 15px;
    min-width: 180px;
    float: left;
    position: relative;
    background-color: #dde0e6;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
  transition: background-color 0.2s ease;
  &:hover {
    text-decoration: none;
    cursor: pointer;
    background-color: #3198e2;
    color: #FFF;
  }
  &.success {
    background-color: #2ab27b;
    color: #fff;
    &:hover {
      background-color: #3198e2;
    }
    &:after {
      border-left: 17px solid #2ab27b;
    }
  }
}

.arrow-steps .step:after,
.arrow-steps .step:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -17px;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 17px solid #dde0e6;    
    z-index: 2;
  transition: border-color 0.2s ease;
}
.arrow-steps .step:hover:after {
  border-left: 17px solid #3198e2;
}
.arrow-steps .step.success:hover:after {
  // border-left: 17px solid #2ab27b;
}

.arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 17px solid #fff;   
    z-index: 0;
}

.arrow-steps .step:first-child:before {
    border: none;
}

.arrow-steps .step:first-child {
    // border-top-left-radius: 4px;
    // border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
    position: relative;
}

.arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -20px;
}

.arrow-steps .step.done span:before {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease 0.5s;
    -moz-transition: opacity 0.3s ease 0.5s;
    -ms-transition: opacity 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
    color: #fff;
    background-color: #3198e2;
}

.arrow-steps .step.current:after {
    border-left: 17px solid #3198e2;    
}
@import 'mobile';
@import 'override';